export function setLoading() {
  return {
    type: '@loading/SET_GLOBAL_LOADING',
  };
}

export function setCustomizationLoaded() {
  return {
    type: '@loading/SET_GLOBAL_CUSTOMIZATION_LOADED',
  };
}
