import { spaceManagementAction, spaceManagementState } from './type';
import produce from 'immer';

const INITIAL_STATE: spaceManagementState = {
  id: null,
  companyId: null,
  spaceManagementEnabled: null,
  organizationId: null,
};

export default function spaceManagement(
  state: spaceManagementState = INITIAL_STATE,
  action: spaceManagementAction
): spaceManagementState {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@spaceManagement/GET_SPACE_MANAGEMENT_SUCCESS': {
        draft.companyId = action.payload.spaceManagement.companyId;
        draft.spaceManagementEnabled = action.payload.spaceManagement.spaceManagementEnabled;
        draft.organizationId = action.payload.spaceManagement.organizationId;
        break;
      }
      case '@spaceManagement/GET_SPACE_MANAGEMENT_FAILURE': {
        draft.id = null;
        draft.companyId = null;
        draft.spaceManagementEnabled = null;
        draft.organizationId = null;
        break;
      }

      default:
    }
  });
}
