import { SpaceManagementModel } from '_/models/spaceManagement';
import { SpaceManagementApi } from '_/services/api';
import { create } from 'zustand';

interface SpaceManagementStore {
  spaceManagement: SpaceManagementModel | undefined;
  getSpaceManagement: (currentCompanyId: string) => void;
  // setSpaceManagement: (value: SpaceManagementModel) => void;
}

const useSpaceManagement = create<SpaceManagementStore>((set) => ({
  spaceManagement: undefined,
  // setSpaceManagement: (value: SpaceManagementModel) => {
  //   set({ spaceManagement: value });
  // },
  getSpaceManagement: async (currentCompanyId: string) => {
    const { data } = await SpaceManagementApi.getSpaceManagement(currentCompanyId);

    if (data?.length > 0) {
      set({ spaceManagement: data[0] });
      return;
    }

    set({ spaceManagement: undefined });
  },
}));

export default useSpaceManagement;
