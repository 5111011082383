import QRCodeScanner from '_/screens/QRCodeReader/QRCodeScanner';
import {
  QRCODE_LOCATION_PREFIX,
  QRCODE_SLOT_PREFIX,
  QRCODE_SPACE_PREFIX,
} from '_/config/qrCodePrefix';
import { showMessage } from 'react-native-flash-message';
import { useTranslation } from 'react-i18next';
import api from '_/services/api/base';
import { colors } from '_/config/theme';
import { format } from 'date-fns';
import { getDateLocale } from '_/helpers/getDateLocale';
import { BackHandler } from 'react-native';
import { useCallback, useEffect, useState } from 'react';
import { appConfig } from '_/config/app';

const QRCodeReaderCheckin = ({ event, onClose }: { event: any; onClose: () => void }) => {
  const [scannerEnabled, setScannerEnabled] = useState(true);
  const { t: translate, i18n } = useTranslation();
  const locale = getDateLocale(i18n.language);

  useEffect(
    useCallback(() => {
      const onBackPress = () => {
        onClose();
        return true;
      };

      BackHandler.addEventListener('hardwareBackPress', onBackPress);

      return () => BackHandler.removeEventListener('hardwareBackPress', onBackPress);
    }, [])
  );

  const checkinByQrCode = async ({ id, slotId }: { id: string; slotId: string }) => {
    try {
      const result = await api.post(
        '/check-in',
        {
          checkinAt: new Date(),
          slotId,
          eventId: id,
        },
        {
          baseURL: appConfig.spaceManagementApi,
          params: { checkinType: 'QR_CODE' },
        }
      );
      return result.data;
    } catch (error) {
      throw error;
    }
  };

  async function handleQRCodeScanned(data: string) {
    try {
      const prefix = data.slice(0, data.lastIndexOf('/') + 1);
      switch (prefix) {
        case QRCODE_SLOT_PREFIX: {
          const slotId = data.replace(QRCODE_SLOT_PREFIX, '');

          if (slotId !== event.slotId) {
            showMessage({
              message: translate('error'),
              description: translate('qrCodeScreen.errorMessages.wrongSlotQrCode'),
              backgroundColor: colors.danger,
            });
            return;
          }

          await checkinByQrCode({ id: event.id, slotId });
          break;
        }
        case QRCODE_LOCATION_PREFIX: {
          const locationId = data.replace(QRCODE_LOCATION_PREFIX, '');

          if (locationId !== event.slot?.space?.location?.id) {
            showMessage({
              message: translate('error'),
              description: translate('qrCodeScreen.errorMessages.wrongSlotQrCode'),
              backgroundColor: colors.danger,
            });
            return;
          }

          await checkinByQrCode({ id: event.id, slotId: event.slotId });
          break;
        }
        case QRCODE_SPACE_PREFIX: {
          const spaceId = data.replace(QRCODE_SPACE_PREFIX, '');

          if (spaceId !== event.slot?.space?.id) {
            showMessage({
              message: translate('error'),
              description: translate('qrCodeScreen.errorMessages.wrongSlotQrCode'),
              backgroundColor: colors.danger,
            });
            return;
          }

          await checkinByQrCode({ id: event.id, slotId: event.slotId });
          break;
        }

        default:
          showMessage({
            message: translate('error'),
            description: translate('qrCodeScreen.errorMessages.invalidQrCode'),
            backgroundColor: colors.danger,
          });
          return;
      }

      showMessage({
        message: translate('success'),
        description: translate('qrCodeScreen.successMessages.checkinSuccess'),
        backgroundColor: colors.success,
      });
      onClose();
    } catch (error: any) {
      const errorData = error?.response?.data?.data;
      switch (errorData?.type) {
        case 'slotNotAvailable':
          showMessage({
            message: translate('error'),
            description: translate('qrCodeScreen.errorMessages.slotNotAvailable'),
            backgroundColor: colors.danger,
          });
          break;
        case 'SlotAlreadyReserved':
          showMessage({
            message: translate('error'),
            description: translate('qrCodeScreen.errorMessages.isThereAnotherReservation', {
              endAt: format(new Date(errorData?.currentEventEndAt), 'HH:mm', {
                locale,
              }),
            }),
            backgroundColor: colors.danger,
          });
        case 'checkinAfterEnded':
          showMessage({
            message: translate('error'),
            description: translate('qrCodeScreen.errorMessages.checkinAfterEnded'),
            backgroundColor: colors.danger,
          });
          break;
        case 'checkinAfterTolerance':
          showMessage({
            message: translate('error'),
            description: translate('qrCodeScreen.errorMessages.checkinAfterTolerance', {
              spaceTolerance: errorData?.spaceTolerance,
            }),
            backgroundColor: colors.danger,
          });
          break;
        default:
          showMessage({
            message: translate('error'),
            description: translate('qrCodeScreen.errorMessages.checkinFail'),
            backgroundColor: colors.danger,
          });
          break;
      }
    }
  }

  return <QRCodeScanner isEnabled={scannerEnabled} handleQRCodeScanned={handleQRCodeScanned} />;
};
export default QRCodeReaderCheckin;
