import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNetInfo } from '@react-native-community/netinfo';
import {
  HostZonesModel,
  HostsModel,
  VisitZonesAccessModel,
  VisitsModel,
  ZonesModel,
} from '@w3lcome/types';
import { appConfig } from '_/config/app';
import logSentryException from '_/helpers/logSentryException';
import { hostZonesApi, visitZonesAccessApi } from '_/services/api';
import AllowedHostsDB from '_/services/sqlite/AllowedHostsDB';
import UsersZonesDB from '_/services/sqlite/UsersZonesDB';
import axios from 'axios';
import { Buffer } from 'buffer';
import React, { createContext, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useUser } from './UserProvider';

interface HostContextData {}

const HostContext = createContext<HostContextData>({} as HostContextData);

type HostType = {
  children: React.ReactNode;
};

export const HostProvider: React.FC<HostType> = ({ children }) => {
  const host = useSelector((state: any) => state.host);

  const netInfo = useNetInfo();

  const { feathersApp } = useUser();

  const isZoneAuthorizationEnabled = useSelector(
    (state: any) => state.company?.customization?.isZoneAuthorizationEnabled
  );

  useEffect(() => {
    getUsersAllowedZones();
  }, [host.id, isZoneAuthorizationEnabled]);

  useEffect(() => {
    feathersApp?.service('company/host-zones').on('created', createdHostZones);
    feathersApp?.service('company/host-zones').on('removed', removeHostZones);
    feathersApp?.service('company/visit-zones-access').on('created', createdVisitZones);
    feathersApp?.service('company/visit-zones-access').on('removed', removeVisitZones);

    return () => {
      feathersApp?.service('company/host-zones').off('created', createdHostZones);
      feathersApp?.service('company/host-zones').off('removed', removeHostZones);
      feathersApp?.service('company/visit-zones-access').off('created', createdVisitZones);
      feathersApp?.service('company/visit-zones-access').off('removed', removeVisitZones);
    };
  }, [feathersApp]);

  useEffect(() => {
    if (netInfo.type === 'wifi') {
      return saveAllowedZones();
    }
  }, [netInfo.type, host.zoneId]);

  function saveAllowedZones() {
    if (!host.zoneId) {
      return;
    }

    if (appConfig.allowedHostZonesOfflineFlowEnabled !== 'true') return;

    const { username, password, endpointUrl } = host.zone;

    if (!endpointUrl || !password || !username) {
      return;
    }

    const credentials = Buffer.from(`${username}:${password}`, 'utf-8').toString('base64');

    AllowedHostsDB.all().then((data) => {
      if (data.length === 0) {
        return;
      }

      data.map((allowedZone, index) => {
        setTimeout(async () => {
          try {
            await axios.post(
              `${endpointUrl}`,
              {
                cardnumber: allowedZone.cardNumber,
                source_name: allowedZone.source_name,
                eventdatetime: allowedZone.eventdatetime,
                eventhwid: allowedZone.eventhwid,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Basic ${credentials}`,
                },
                timeout: 2000,
              }
            );
            AllowedHostsDB.deleteOne(allowedZone.id, allowedZone.source_name);
          } catch (error) {
            logSentryException({
              error,
              file: 'HostProvider.tsx',
              message: 'Error at saveAllowedZones function',
            });
          }
        }, 500 * index);
      });
    });
  }

  async function getUsersAllowedZones() {
    if (!isZoneAuthorizationEnabled) {
      return;
    }

    if (host.zoneId && appConfig.allowedHostZonesOfflineFlowEnabled === 'true') {
      try {
        const lastUpdateTimeHostsZones = await AsyncStorage.getItem(
          '@w3lcome/lastUpdateTimeHostsZones'
        );

        let createdAtFilter = {};

        if (lastUpdateTimeHostsZones) {
          const lastUpdateTime = new Date(lastUpdateTimeHostsZones).getTime();
          const currentTimestamp = Date.now();
          const FOUR_HOURS_IN_MS = 4 * 60 * 60 * 1000;

          if (currentTimestamp - lastUpdateTime <= FOUR_HOURS_IN_MS) {
            createdAtFilter = { 'createdAt[$gt]': new Date(lastUpdateTimeHostsZones) };
          }

          if (currentTimestamp - lastUpdateTime > FOUR_HOURS_IN_MS) {
            await UsersZonesDB.deleteAll();
          }
        }

        const dataHostsZones = await hostZonesApi.getHostsZones({
          zoneId: host.zoneId,
          includeHosts: true,
          ...createdAtFilter,
        });

        await createAllHostZones(dataHostsZones);

        const dataVisitsZones = await visitZonesAccessApi.getVisitsZonesAccess({
          zoneId: host.zoneId,
          includeVisits: true,
          ...createdAtFilter,
        });

        await createAllVisitZones(dataVisitsZones);

        await AsyncStorage.setItem('@w3lcome/lastUpdateTimeHostsZones', `${new Date()}`);
      } catch (error) {
        logSentryException({
          error,
          file: 'HostProvider.tsx',
          message: 'Error at getUsersAllowedZones function',
        });
      }
    }
  }

  const formatMifareCardNumber = (mifareCardNumber: string[] | null | undefined) => {
    if (!mifareCardNumber) {
      return '';
    }

    const mifareCardNumberStr = Array.isArray(mifareCardNumber)
      ? mifareCardNumber.join('|')
      : mifareCardNumber;

    return `|${mifareCardNumberStr}|`;
  };

  const createUserZone = (
    zoneId: string,
    userId: string,
    cardNumber: string | undefined,
    mifareCardNumber: string[] | null | undefined,
    user: HostsModel | VisitsModel,
    zone: ZonesModel | null | undefined
  ) => {
    return UsersZonesDB.create({
      zoneId,
      userId,
      cardNumber: cardNumber || '',
      mifareCardNumber: formatMifareCardNumber(mifareCardNumber),
      userZone: { user, zone },
    });
  };

  async function createdHostZones(data: HostZonesModel) {
    if (!isZoneAuthorizationEnabled) {
      return;
    }

    if (host.zoneId && host.zoneId === data.zoneId) {
      createUserZone(
        data.zoneId,
        data.hostId,
        data.host?.metadata?.wAccessCardNumber,
        data.host?.mifareCardNumber,
        data.host,
        data.zone
      );
    }
  }

  async function removeHostZones(data: HostZonesModel) {
    if (!isZoneAuthorizationEnabled) {
      return;
    }

    if (host.zoneId && host.zoneId === data.zoneId) {
      UsersZonesDB.deleteOne(data.hostId, data.zoneId);
    }
  }

  async function createdVisitZones(data: VisitZonesAccessModel) {
    if (!isZoneAuthorizationEnabled) {
      return;
    }

    if (host.zoneId && host.zoneId === data.zoneId) {
      createUserZone(
        data.zoneId,
        data.visitId,
        data.visit?.cardNumber,
        data.visit?.mifareCardNumber,
        data.visit as VisitsModel,
        data.zone
      );
    }
  }

  async function removeVisitZones(data: VisitZonesAccessModel) {
    if (!isZoneAuthorizationEnabled) {
      return;
    }

    if (host.zoneId && host.zoneId === data.zoneId) {
      UsersZonesDB.deleteOne(data.visitId, data.zoneId);
    }
  }

  const createAllHostZones = (hostsZones: HostZonesModel[]) => {
    const createPromises = hostsZones.map((hostZone) => {
      return createUserZone(
        hostZone.zoneId,
        hostZone.hostId,
        hostZone.host?.metadata?.wAccessCardNumber,
        hostZone.host?.mifareCardNumber,
        hostZone.host,
        hostZone.zone
      );
    });

    return Promise.all(createPromises);
  };

  const createAllVisitZones = (visitsZones: VisitZonesAccessModel[]) => {
    const createPromises = visitsZones.map((visitZone) => {
      return createUserZone(
        visitZone.zoneId,
        visitZone.visitId,
        visitZone.visit?.cardNumber,
        visitZone.visit?.mifareCardNumber,
        visitZone.visit as VisitsModel,
        visitZone.zone
      );
    });

    return Promise.all(createPromises);
  };

  return <HostContext.Provider value={{}}>{children}</HostContext.Provider>;
};

export function useHost() {
  const context = useContext(HostContext);

  if (!context) {
    throw new Error('useHost must be used within an HostProvider');
  }

  return context;
}
