import { AxiosRequestConfig } from 'axios';

import api from './base';
import isInvenzi from '_/helpers/isInvenzi';
import { appConfig } from '_/config/app';

interface NotificationTokenData {
  deviceId: string;
  token: string;
  userId: string;
  type: string;
  spaceManagementEnabled?: boolean;
}

class NotificationTokens {
  private spaceManagementNotification = '/user-tokens';

  public async getAllNotificationTokens(params: any, config?: AxiosRequestConfig) {
    const { data } = await api.get('/notification-tokens', {
      ...config,
      params: {
        ...params,
      },
    });
    if (data) {
      return data;
    } else {
      return undefined;
    }
  }

  // mudar o id para deviceId: string;
  public async getNotificationTokenById(id: string) {
    const { data } = await api.get(`/notification-tokens/${id}`);
    return data;
  }

  public async createNotificationToken({
    deviceId,
    token,
    userId,
    type,
    spaceManagementEnabled,
  }: NotificationTokenData) {
    const { data } = await api.post('/notification-tokens', {
      token,
      userId,
      type,
      deviceId,
    });
    if (isInvenzi() && spaceManagementEnabled) {
      await api.post(
        this.spaceManagementNotification,
        { token, userId, type, deviceId },
        {
          baseURL: appConfig.spaceManagementApi,
        }
      );
    }

    return data;
  }

  // mudar o id para deviceId: string;
  public async updateNotificationToken({
    deviceId,
    token,
    userId,
    type,
    spaceManagementEnabled,
  }: NotificationTokenData) {
    await api.patch(`/notification-tokens/${deviceId}`, {
      token,
      userId,
      type,
    });
    if (isInvenzi() && spaceManagementEnabled) {
      await api.patch(
        `${this.spaceManagementNotification}/${deviceId}`,
        { token, userId, type },
        {
          baseURL: appConfig.spaceManagementApi,
        }
      );
    }
  }

  // mudar o id para deviceId: string;
  public async deleteNotificationToken(id: string, spaceManagementEnabled?: boolean) {
    try {
      await api.delete(`/notification-tokens/${id}`);
      if (isInvenzi() && spaceManagementEnabled) {
        await api.delete(`${this.spaceManagementNotification}/${id}`, {
          baseURL: appConfig.spaceManagementApi,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
}

export default new NotificationTokens();
