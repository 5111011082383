import { Feather } from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';
import { MeetingsModel, VisitsModel } from '@w3lcome/types';
import { Text } from '_/components';
import themes from '_/constants/themes';
import logSentryException from '_/helpers/logSentryException';
import { hostsApi } from '_/services/api';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity, ActivityIndicator } from 'react-native';
import { showMessage } from 'react-native-flash-message';

import vistsApi from '../../../services/api/visits';
import Invitee from './Invitee';
import styles from './styles';

interface InviteeListProps {
  meeting: MeetingsModel;
}

export default function InviteeList({ meeting }: InviteeListProps) {
  const [invitees, setInvitees] = useState<any[]>([]);
  const [loadingInvitees, setLoadingInvitees] = useState(false);
  const [expandedInvitees, setExpandedInvitees] = useState(true);
  const { t: translate } = useTranslation();

  useFocusEffect(
    useCallback(() => {
      async function loadInvitees() {
        try {
          setLoadingInvitees(true);

          const meetingVisits = await vistsApi.getVisits({
            meetingId: meeting?.id,
          });

          let newInvitees = meetingVisits.data;

          if (meeting?.coHosts?.length === 0) {
            const meetingHosts = await hostsApi.getMeetingHosts(meeting?.id);

            if (meetingHosts?.length > 0) {
              const hosts = meetingHosts?.map((host) => ({
                ...host.host,
                checkinAt: true,
              }));
              newInvitees = newInvitees.concat(hosts as unknown as ConcatArray<VisitsModel>);
            }
          }

          setInvitees(newInvitees);

          setLoadingInvitees(false);
        } catch (error) {
          setLoadingInvitees(false);
          if (error.response?.data.code !== 400) {
            showMessage({
              message: translate('InviteeList.error'),
              type: 'danger',
            });
            logSentryException({
              error,
              file: 'InviteeList.js',
              message: 'Error at loadInvitees function',
            });
          }
        }
      }
      loadInvitees();
    }, [meeting?.id, translate])
  );

  function renderInvitees() {
    function toggleExpandedInvitees() {
      setExpandedInvitees(!expandedInvitees);
    }

    if (!loadingInvitees && !invitees?.length) {
      return;
    }

    if (loadingInvitees) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ marginRight: 5 }}>{translate('meeting.loadingInvitees')}</Text>
          <ActivityIndicator
            size="small"
            color={themes.colors.primary}
            animating={loadingInvitees}
          />
        </View>
      );
    }

    return (
      <>
        <TouchableOpacity style={styles.rowContainer} onPress={toggleExpandedInvitees}>
          <View>
            <Text style={styles.title}>{translate('meeting.invitees')}</Text>
          </View>

          <Feather name={expandedInvitees ? 'chevron-up' : 'chevron-down'} style={styles.icons} />
        </TouchableOpacity>
        {expandedInvitees &&
          invitees.map((invitee) => <Invitee key={invitee?.id} invitee={invitee} />)}
      </>
    );
  }

  return <View style={styles.container}>{renderInvitees()}</View>;
}
