import { SpaceManagementModel } from '_/models/spaceManagement';
import { action } from 'typesafe-actions';

export const getSpaceManagementRequest = (companyId: string) =>
  action('@spaceManagement/GET_SPACE_MANAGEMENT_REQUEST', { companyId });

export const getSpaceManagementSuccess = (spaceManagement: SpaceManagementModel) =>
  action('@spaceManagement/GET_SPACE_MANAGEMENT_SUCCESS', { spaceManagement });

export const getSpaceManagementFailure = () =>
  action('@spaceManagement/GET_SPACE_MANAGEMENT_FAILURE');
