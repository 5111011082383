import themes from '_/constants/themes';
import { isBefore } from 'date-fns';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import React, { useEffect } from 'react';
import { View, Platform, StatusBar, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import ContainerWidthLimit from './components/ContainerWidthLimit';
import { usePushNotificationContext } from './hooks/PushNotificationProvider';
import Routes from './routes';
import useSpaceManagement from '_/hooks/useSpaceManagement';

// - MIGRATION -
const LIMITED_ACCESS_COMPANY_ID = '9501fedf-3f2f-4e9c-b20e-d8d39097f919';
// - MIGRATION -

export default function AppNavigator() {
  const { id: userId, currentCompanyId } = useSelector((state: any) => state.user);
  const waccessIntegration = useSelector((state: any) => state.company.waccessIntegration);
  const spaceManagement = useSpaceManagement((state) => state.spaceManagement);
  const getSpaceManagement = useSpaceManagement((state) => state.getSpaceManagement);
  const auth = useSelector((state: any) => state.auth);
  const role = useSelector((state: any) => state.host.role);
  const waccessIntegrationOnlyQRCode = useSelector(
    (state: any) => state.company.customization?.waccessIntegrationOnlyQRCode
  );
  const { trialUntil, planIsActive, id: companyId } = useSelector((state: any) => state.company);
  const { handleNotification, verifyIfShouldShowModal } = usePushNotificationContext();

  useEffect(() => {
    const listener = Notifications.addNotificationResponseReceivedListener(handleNotification);

    return () => {
      listener.remove();
    };
  }, [handleNotification]);

  useEffect(() => {
    if (currentCompanyId) getSpaceManagement(currentCompanyId);
  }, [currentCompanyId]);

  // Checar se há notificação previamente configurada para o dispositivo
  useEffect(() => {
    async function verifyShowModal() {
      await verifyIfShouldShowModal();
    }
    if (userId) {
      verifyShowModal();
    }
  }, [userId]);

  return (
    <View style={styles.container}>
      <StatusBar barStyle="dark-content" backgroundColor={themes.colors.white} />
      <ContainerWidthLimit>
        <Routes
          auth={{
            ...auth,
            role,
            companyId,
            spaceManagement,
          }}
          onlyDynamicQRCode={waccessIntegrationOnlyQRCode}
          expired={!planIsActive && isBefore(trialUntil, new Date())}
          showQRCodePagefirst={!!waccessIntegration?.aesKey}
          // - MIGRATION -
          isAccessLimited={companyId === LIMITED_ACCESS_COMPANY_ID}
          // - MIGRATION -
        />
      </ContainerWidthLimit>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Platform.OS === 'web' ? themes.colors.lightPrimary : themes.colors.white,
    flex: 1,
    paddingTop: Platform.OS === 'ios' ? Constants.statusBarHeight : 0,
  },
});
