import api from './base';
import { SpaceManagementModel } from '_/models/spaceManagement';
import { GetListType } from './types/GetListType';

class SpaceManagementApi {
  public getSpaceManagement = async (companyId: string) => {
    const { data } = await api.get<GetListType<SpaceManagementModel>>('/space-management/', {
      params: { companyId },
    });
    return data;
  };
}

export default new SpaceManagementApi();
