import { useFocusEffect } from '@react-navigation/native';
import { TopMenu } from '_/components';
import { AxiosHeaderValue } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BackHandler, RefreshControl, ScrollView } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import WebView, { WebViewMessageEvent } from 'react-native-webview';
import api from '_/services/api/base';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import QRCodeReaderCheckin from './QrCodeCheckin';
import { appConfig } from '_/config/app';
import { useSelector } from 'react-redux';
import useSpaceManagement from '_/hooks/useSpaceManagement';

export default function SpaceManagement() {
  const [webViewKey, setWebViewKey] = useState(0);
  const [accessToken, setAccessToken] = useState<AxiosHeaderValue>();
  const [refreshing, setRefreshing] = useState(false);
  const [scanQrCode, setScanQrCode] = useState(false);
  const [event, setEvent] = useState({} as any);
  const currentCompanyId = useSelector((state: any) => state.user.currentCompanyId);
  const spaceManagement = useSpaceManagement((state) => state.spaceManagement);
  const webviewRef = useRef(null);

  useFocusEffect(
    useCallback(() => {
      setWebViewKey((prevKey) => prevKey + 1);
    }, [])
  );

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        if (webviewRef.current) {
          (webviewRef.current as any).postMessage(JSON.stringify({ action: 'back' }));
          return true;
        }
        return false;
      };

      BackHandler.addEventListener('hardwareBackPress', onBackPress);

      return () => BackHandler.removeEventListener('hardwareBackPress', onBackPress);
    }, [])
  );

  useEffect(() => {
    setAccessToken((api.defaults.headers.common.authorization as string)?.split(' ')[1]);
  }, []);

  useEffect(() => {
    setWebViewKey((prevKey) => prevKey + 1);
  }, [currentCompanyId]);

  const runBeforeLoading = `
    (function () {
      const urlParams = new URLSearchParams(window.location.search);
      const tokenUrl = urlParams.get('token');
      if (tokenUrl) {
        const storagedToken = localStorage.getItem('@space_management:token');
        if (storagedToken !== tokenUrl) {
          localStorage.setItem('@space_management:token', tokenUrl);
          localStorage.setItem('@space_management:webview', 'true')
          window.location.reload();
        }
      }
    })();
    true;
  `;

  const runAfterLoading = `
    (function () {
      if (window.hasMessageListener) return;

      window.hasMessageListener = true;
      let lastExecution = 0;

      document.addEventListener('message', function (event) {
        const backBtn = document.getElementById('ArrowBackHeader');
        const message = JSON.parse(event.data);

        if (message.action === 'back') {
          const now = Date.now();
          if (now - lastExecution < 1000) {
            return;
          }
          lastExecution = now;
          backBtn?.click();
        }
      });
    })();
    true;
  `;

  if (scanQrCode) {
    return <QRCodeReaderCheckin event={event} onClose={() => setScanQrCode(false)} />;
  }

  return (
    <ContainerWidthLimit>
      <ScrollView
        style={{ maxHeight: 80 }}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={() => {
              setRefreshing(true);
              setWebViewKey((prev) => prev + 1);
              const time = Math.floor(Math.random() * 1500) + 500;
              setTimeout(() => setRefreshing(false), time);
            }}
          ></RefreshControl>
        }
      >
        <TopMenu />
      </ScrollView>
      {!accessToken ? null : (
        <WebView
          ref={webviewRef}
          key={webViewKey}
          javaScriptEnabled={true}
          domStorageEnabled={true}
          originWhitelist={['*']}
          sharedCookiesEnabled={true}
          mediaPlaybackRequiresUserAction={false}
          allowsInlineMediaPlayback={true}
          injectedJavaScriptBeforeContentLoaded={runBeforeLoading}
          injectedJavaScript={runAfterLoading}
          source={{
            uri: `${
              appConfig.spaceManagementWebApp
            }/?token=${accessToken}&webView=${true}&currentOrganizationId=${
              spaceManagement?.organizationId
            }`,
          }}
          onMessage={(m: WebViewMessageEvent) => {
            if (m.nativeEvent.data.startsWith('{') && m.nativeEvent.data.endsWith('}')) {
              const data = JSON.parse(m.nativeEvent.data);
              if (data.message === 'checkin') {
                setEvent(data.event);
                setScanQrCode(true);
                return;
              }
              if (data.message && data.description && data.type)
                return showMessage({
                  message: data.message,
                  description: data.description,
                  type: data.type,
                });
            }
            console.log(m.nativeEvent.data);
          }}
        />
      )}
    </ContainerWidthLimit>
  );
}
