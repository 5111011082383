import { call, put, takeLatest } from 'redux-saga/effects';
import { SpaceManagementApi } from '_/services/api';
import * as actions from './actions';
import { all } from 'axios';

export function* getSpaceManagement(action: ReturnType<typeof actions.getSpaceManagementRequest>) {
  console.log('function* getSpaceManagement');
  try {
    const { data } = yield call(SpaceManagementApi.getSpaceManagement, action.payload?.companyId);
    yield put(actions.getSpaceManagementSuccess(data[0]));
  } catch (error) {
    yield put(actions.getSpaceManagementFailure());
  }
}

export default all([
  takeLatest('@spaceManagement/GET_SPACE_MANAGEMENT_REQUEST', getSpaceManagement),
]);
