import { HostsModel, VisitsModel, ZonesModel } from '@w3lcome/types';
import { translate } from '_/locales';
import { HostQRcodeCreatedProps } from '_/screens/EntryReader';
import AllowedHostsDB from '_/services/sqlite/AllowedHostsDB';
import UsersZonesDB from '_/services/sqlite/UsersZonesDB';
import moment from 'moment';
import uuid from 'react-native-uuid';

import { checkMeetingTime } from './checkMeetingTime';
import { showError } from './showError';

// Validações da invenzi
const EVENT_INVALID_CARD = 13;
const EVENT_VALID_ACCESS = 71;
const EVENT_INVALID_ACCESS_TIME = 17;

async function handleValidVisitAccess(
  visit: VisitsModel,
  zone: ZonesModel | null | undefined,
  cardNumber: string,
  hostZoneName: string,
  setHostQRcodeCreated: (value: HostQRcodeCreatedProps) => void
) {
  const now = new Date();

  if (visit?.meeting && checkMeetingTime(visit?.meeting.start, visit?.meeting.end)) {
    await createEventInDB(cardNumber, hostZoneName, EVENT_VALID_ACCESS);

    setHostQRcodeCreated({
      data: visit,
      isZoneAuthorized: true,
      allowedZones: [{ zone }],
    });
  } else if (visit.finishCheckinAt && moment(now).isSame(visit.finishCheckinAt, 'day')) {
    await createEventInDB(cardNumber, hostZoneName, EVENT_VALID_ACCESS);

    setHostQRcodeCreated({
      data: visit,
      isZoneAuthorized: true,
      allowedZones: [{ zone }],
    });
  } else {
    await createEventInDB(cardNumber, hostZoneName, EVENT_INVALID_ACCESS_TIME);
    showError(translate('qrCodeScan.invalidAccessTime'));
  }
}

export async function handleVerifyCardLocalDB(
  card: string,
  host: HostsModel,
  setHostQRcodeCreated: (value: HostQRcodeCreatedProps) => void,
  isMifareCard = false
) {
  if (!host.zoneId || !host.zone) {
    return;
  }

  const userAllowed = isMifareCard
    ? await UsersZonesDB.getOneMifareCardNumber(card, host.zoneId)
    : await UsersZonesDB.getOne(card, host.zoneId);

  if (userAllowed) {
    const { userZone } = userAllowed;

    if ('meeting' in userZone.user || 'finishCheckinAt' in userZone.user) {
      await handleValidVisitAccess(
        userZone.user,
        userZone.zone,
        card,
        host.zone.name,
        setHostQRcodeCreated
      );

      return;
    }

    await createEventInDB(card, host.zone.name, EVENT_VALID_ACCESS);

    setHostQRcodeCreated({
      data: userZone.user,
      isZoneAuthorized: true,
      allowedZones: [{ zone: userZone.zone }],
    });

    return;
  }

  await createEventInDB(card, host.zone.name, EVENT_INVALID_CARD);
  showError(translate('qrCodeScan.userNotAllowed'));
}

async function createEventInDB(cardNumber: string, zoneName: string, eventhwid: number) {
  await AllowedHostsDB.create({
    id: uuid.v4().toString(),
    cardNumber,
    source_name: zoneName,
    eventdatetime: new Date().toISOString(),
    eventhwid,
  });
}
