import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { VisitAuthorizationStatusType } from '@w3lcome/types';
import { Header, InfoCard, PictureContainer, Text } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import ModalCancelVisit from '_/components/ModalCancelVisit';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { useVisitsContext } from '_/hooks/VisitsProvider';
import { VisitorProps } from '_/routes/types';
import * as Localization from 'expo-localization';
import { AsYouType, CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment-timezone';
import { transparentize } from 'polished';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  View,
  Platform,
  ScrollView,
  SafeAreaView,
  TouchableOpacity,
  KeyboardAvoidingView,
  StyleSheet,
  Dimensions,
} from 'react-native';
import { useSelector } from 'react-redux';

import DelayVisitCard from './DelayVisitCard';
import DenyConfirmationModal from './DenyConfirmationModal';
import EditableInfoCard from './EditableInfoCard';

export default function Visitor() {
  const navigation = useNavigation<VisitorProps>();
  const { t: translate } = useTranslation();

  const { timezone, customization } = useSelector((state: any) => state.company);
  const { selectedVisit: visit, allowDenyVisit, updateVisit, cancelVisit } = useVisitsContext();
  const [privateNotes, setPrivateNotes] = useState(visit?.notes || '');

  const [denyConfirmationModalVisible, setDenyConfirmationModalVisible] = useState(false);
  const [cancelVisitModalVisible, setCancelVisitModalVisible] = useState(false);

  const isMeetingActive = useMemo(() => {
    return moment(visit?.meeting?.end).isSameOrAfter(moment());
  }, [visit?.meeting?.end]);

  const formattedNumber = useMemo(() => {
    let countryCode: CountryCode | undefined;

    if (visit?.phone) {
      const { country } = parsePhoneNumber(`+${visit.phone}`);
      countryCode = country;

      return new AsYouType(countryCode ?? 'BR').input(`+${visit.phone}`);
    }
  }, [visit?.phone]);

  const showDelayVisitCard =
    customization?.delayVisitEnabled &&
    visit?.authorizationStatus === VisitAuthorizationStatusType.AWAITING &&
    !visit.checkoutAt &&
    !visit.isCanceled;

  const handlePrivateNotesSubmit = () => {
    if (privateNotes !== visit?.notes && visit?.id) {
      updateVisit(visit.id, { notes: privateNotes });
    }
  };

  function allowVisit() {
    if (visit?.id) {
      allowDenyVisit(visit.id, VisitAuthorizationStatusType.ALLOWED);
    }
  }

  function showDenyConfirmationModal() {
    setDenyConfirmationModalVisible(true);
  }

  function denyVisit(inputNotes?: string | null) {
    if (visit?.id) {
      const visitorNoteDenied = inputNotes?.trim() ? inputNotes : null;

      setDenyConfirmationModalVisible(false);

      allowDenyVisit(visit.id, VisitAuthorizationStatusType.DENIED, visitorNoteDenied);
    }
  }

  async function handleCancelVisit() {
    if (visit?.id) {
      setCancelVisitModalVisible(false);
      cancelVisit(visit.id);
    }
  }

  if (!visit) {
    return null;
  }

  return (
    <ContainerWidthLimit>
      <SafeAreaView style={styles.container}>
        <Header handleBack={() => navigation.goBack()} title={translate('visitor.visitor')} />
        <KeyboardAvoidingView
          enabled
          style={{ flex: 1 }}
          keyboardVerticalOffset={46}
          behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        >
          <View style={{ flex: 1 }}>
            <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
              <PictureContainer
                type="visit"
                pictureUrl={visit.pictureUrl}
                status={visit.authorizationStatus}
                isVisitCanceled={visit.isCanceled}
              />
              <View style={styles.content}>
                {visit.authorizationStatus === VisitAuthorizationStatusType.AWAITING &&
                  visit.checkinAt && (
                    <View style={styles.actionsView}>
                      <TouchableOpacity
                        style={[styles.btn, styles.btnDeny]}
                        onPress={showDenyConfirmationModal}
                      >
                        <Text style={[styles.btnText, styles.danger]}>
                          {translate('visitor.deny')}
                        </Text>
                        <Feather name="x" style={[styles.icons, styles.danger]} />
                      </TouchableOpacity>
                      <TouchableOpacity onPress={allowVisit} style={[styles.btn, styles.btnAllow]}>
                        <Text style={[styles.btnText, styles.success]}>
                          {translate('visitor.allow')}
                        </Text>
                        <Feather name="check" style={[styles.icons, styles.success]} />
                      </TouchableOpacity>
                    </View>
                  )}

                <InfoCard copy title={translate('general.name')} value={visit.name} />
                <InfoCard
                  copy
                  whatsapp
                  title={translate('visitor.phone')}
                  value={formattedNumber}
                />
                <InfoCard copy title={translate('visitor.email')} value={visit.email} />
                <InfoCard copy title={translate('visitor.company')} value={visit.companyname} />
                <InfoCard copy title={translate('visitor.department')} value={visit.sector} />
                <InfoCard copy title={translate('visitor.position')} value={visit.position} />
                <InfoCard
                  title={translate('visitor.expectedAt')}
                  value={
                    visit.expectedAt &&
                    moment(visit.expectedAt)
                      .tz(timezone || Localization.timezone || 'America/Bahia')
                      .format('DD/MM/YYYY - HH:mm')
                  }
                />
                <InfoCard
                  title={translate('visitor.checkin')}
                  value={
                    visit.checkinAt &&
                    moment(visit.checkinAt)
                      .tz(timezone || Localization.timezone || 'America/Bahia')
                      .format('DD/MM/YYYY - HH:mm')
                  }
                />

                <InfoCard
                  title={translate('visitor.checkout')}
                  value={
                    visit.checkoutAt &&
                    moment(visit.checkoutAt)
                      .tz(timezone || Localization.timezone || 'America/Bahia')
                      .format('DD/MM/YYYY - HH:mm')
                  }
                />

                <InfoCard title={translate('general.meeting')} value={visit?.meeting?.title} />

                <EditableInfoCard
                  label={translate('visitor.privateNotes')}
                  value={privateNotes}
                  onChangeText={(text) => setPrivateNotes(text)}
                  handleSubmit={handlePrivateNotesSubmit}
                />

                {showDelayVisitCard && <DelayVisitCard visit={visit} />}

                {isMeetingActive && !visit.checkinAt && !visit.isCanceled && (
                  <View style={{ alignItems: 'center' }}>
                    <TouchableOpacity
                      style={styles.cancelButton}
                      onPress={() => setCancelVisitModalVisible(true)}
                    >
                      <Text style={styles.cancelButtonText}>
                        {translate('visitor.cancelVisit')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </ScrollView>
          </View>
        </KeyboardAvoidingView>

        <DenyConfirmationModal
          visible={denyConfirmationModalVisible}
          hideModal={() => setDenyConfirmationModalVisible(false)}
          denyVisit={denyVisit}
        />
        <ModalCancelVisit
          isVisible={cancelVisitModalVisible}
          onHideModal={setCancelVisitModalVisible}
          onCancel={handleCancelVisit}
        />
      </SafeAreaView>
    </ContainerWidthLimit>
  );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    backgroundColor: themes.colors.white,
    flex: 1,
  },
  content: {
    marginTop: 16,
    paddingBottom: 24,
    paddingHorizontal: 24,
  },
  actionsView: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    marginVertical: 8,
    width: '100%',
  },
  btn: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 34,
    borderWidth: 1.5,
    width: Platform.OS === 'web' ? width / 5 : width / 2 - 30,
    borderRadius: 2,
  },
  btnText: {
    fontFamily: fontFamily.medium,
    fontSize: 16,
    marginRight: 8,
  },
  btnAllow: {
    borderColor: transparentize(0.2, themes.colors.success),
  },
  btnDeny: {
    borderColor: transparentize(0.2, themes.colors.danger),
  },
  icons: {
    fontSize: 16,
  },
  danger: {
    color: themes.colors.danger,
  },
  success: {
    color: themes.colors.success,
  },
  cancelButton: {
    width: '100%',
    marginVertical: 8,
    backgroundColor: themes.colors.danger,
    borderRadius: 6,
    alignItems: 'center',
    paddingVertical: 8,
  },
  cancelButtonText: {
    color: themes.colors.white,
    fontSize: 16,
  },
});
